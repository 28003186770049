import React, {useState} from "react"
import { Formik, Form, useField, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import styled from "styled-components"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { makeStyles, withStyles } from "@material-ui/core/styles"


const useStyles = makeStyles(theme => ({
  formContainer: {
    borderRadius: "1rem",
    background: "var(--color-white-medium)",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  inputContainer: {
    height: "100%",
  },

  buttonSize: {
    height: "5rem",
    padding: "0 3rem",
  },
  buttonTextSize: {
    fontSize: "2rem",
    color: "rgba(255, 115, 115, 0.7)",
    border: `1px solid  #1111`,
    textTransform: "capitalize",

    "&:hover": {
      color: "var(--color-primary-dark)",

      border: `1px solid  var(--color-primary-dark)`,
    },
  },

  messageContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '35%',
    backgroundColor: 'var(--color-white-medium)',
    borderRadius: '1rem',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  responseTitle: {
    color: 'var(--color-primary-medium)'
  }
}))

const CssTextField = withStyles({
  root: {
    "&.MuiTextField-root": {
      marginTop: "2rem",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--color-primary-light)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 115, 115, 0.5)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
    },
  },
})(TextField)

const FormInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <CssTextField
      variant="outlined"
      id={field.name}
      name={field.name}
      helperText={meta.touched ? meta.error : ""}
      error={meta.touched && Boolean(meta.error)}
      value={field.value}
      onChange={field.onChange}
      {...props}
      inputProps={{
        style: { fontSize: "2rem" },
      }}
      FormHelperTextProps={{
        style: { fontSize: "1.5rem" },
      }}
    />
  )
}

const MyStyledTextarea = styled.textarea`
  padding: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 1rem;
  width: 100%;
  min-height: 15em;
  max-height: 18em;
  max-width: 100%;
  overflow: auto;

  @media screen and (max-height: 600px) {
    max-height: 10em;
    min-height: 0;
  }
`

const ErrorMessageStyled = styled.div`
  margin-top: 5px;
  font-size: 1.5rem;
  color: var(--color-primary-dark);
`
const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


const EmailForm = () => {
  const classes = useStyles()
  const [state, setState] = useState({})
  const [open, setOpen] = useState(false);
  const [responseType, setResponseType] = useState('')

  
  const handleOpen = (status) => {
    if (status === 'fail') setResponseType('fail');
    if (status === 'success') setResponseType('success');

    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  
  return (
    <Box minHeight="80%" minWidth="50%" className={classes.formContainer}>
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby={responseType}    
    aria-describedby={responseType}
    disableAutoFocus
    disableEnforceFocus
    >
      <div  className={classes.messageContainer}>
      {responseType === 'success' ?
      (
        <>
          <Typography variant='h5' className={classes.responseTitle}>Roger Dodger, you ol' codger!</Typography>
          <Typography variant='h5' className={classes.responseTitle}>Thanks for the message!</Typography>
        </>
        ):
        (
          <>
            <Typography variant='h5' className={classes.responseTitle}>Oh dear! Something went wrong</Typography>
            <Typography variant='h5' className={classes.responseTitle}>I didn't get the message!</Typography>
          </>
        )
      }
      </div>
  </Modal>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
          email: Yup.string()
            .email("That's not an email address!")
            .required("Required"),
          message: Yup.string()
            .min(5, "At least say hello (5 letters)")
            .required(`You didn't leave a message!`),
        })}
        onSubmit={
          (values, actions) => {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "contact-portfolio", ...values })
            })
            .then(() => {
              handleOpen('success')
              actions.resetForm()
            })
            .catch(() => {
              handleOpen('failed')
            })
            .finally(() => actions.setSubmitting(false))
          }
        }
      >
        <Form className={classes.inputContainer} name="contact-portfolio"  data-netlify-honeypot="bot-field" data-netlify={true}>
        <input type='hidden' name='form-name' value='contact-portfolio'/>
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            wrap="nowrap"
            className={classes.inputContainer}
          >
            <Grid
              container
              item
              direction="column"
              justify="center"
              sm={10}
              style={{ flex: "0 1 30%" }}
            >
              <FormInput
                label="Name"
                name="name"
                type="text"
                placeholder="Name"
              />
              <FormInput
                label="Email Address"
                name="email"
                type="email"
                placeholder="Email"
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              justify="space-around"
              alignItems="center"
              style={{ flex: "1 0 60%" }}
            >
              <Grid
                container
                item
                direction="column"
                justify="center"
                alignItems="center"
                spacing={3}
                sm={10}
                xs={8}
                wrap="nowrap"
              >
                <Field name="message" as={MyStyledTextarea} rows={10} />
                <ErrorMessage name="message" component={ErrorMessageStyled} />
              </Grid>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                type="submit"
                classes={{
                  sizeLarge: classes.buttonSize,
                  outlinedSizeLarge: classes.buttonTextSize,
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Box>
  )
}

export default EmailForm
