import React from 'react'

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
FooterContainer: {
    height: '10vh',
    width: '100%',
    background: 'var(--color-white-medium)',
},

footerText: {
    color: 'var(--color-primary-dark)',
    margin: '0 1rem',
}

})

const date = new Date();
const currentYear = date.getFullYear();

const Footer = () => {
const classes = useStyles()
    return(
   <Grid container justify='center' alignItems='center' className={classes.FooterContainer}>
        <Typography className={classes.footerText} variant='h5'>&copy;</Typography>
        <Typography className={classes.footerText} variant='h5'>Alex Krippner</Typography>
        <Typography className={classes.footerText} variant='h5'>{currentYear}</Typography>

   </Grid>
   
    )
}

export default Footer