import styled, { css } from "styled-components"

// object of screenSizes to reduce into object with media query creating methods
const screenSizes = {
  //
  // laptop: 1200,
  // tabletLarge: 900,
  // tabletMedium: 770,
  tabletSmall: 600,
  mobileLarge: 430,
}
const media = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media only screen and (max-width: ${screenSizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const formatCss = styleObject => {
  return (
    JSON.stringify(styleObject)
      .replace(/[{}"']/g, "")
      .replace(/,/g, ";") + ";"
  )
}

const mapQueries = myQueries => {
  return Object.keys(myQueries).map(
    key => media[key]`
  ${formatCss(myQueries[key])}
  `
  )
}

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: ${props => props.flexDirection || "row"};
  height: 100vh;
  width: 100vw;
  background: var(${props => props.bgColor});
  overflow: ${props => props.overflow || ''};
`

export const Separator = styled.div`
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  align-self: ${props => props.alignSelf || "flex-end"};
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 1rem;
  background-color: var(${props => props.color || "--color-primary-light"});
  z-index: 1;

  ${({ myQueries }) => (!myQueries ? "" : mapQueries(myQueries))}
`

