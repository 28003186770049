import React from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import EmailForm from "../components/form"
import { Container } from "../components/styledComponents"

const useStyles = makeStyles({
  sectionTitle: {
    color: "var(--color-primary-light)",
  },
})

const Contact = () => {
  const classes = useStyles()

  return (
    <Container bgColor="--color-primary-dark">
      <Grid container justify="center" alignItems="center" direction="column" wrap='nowrap'>
        <Typography variant="h4" className={classes.sectionTitle}>
          Get in touch
        </Typography>
        <EmailForm />
      </Grid>
    </Container>
  )
}

export default Contact
