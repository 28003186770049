import React from "react"
import {
  useScrollSection,
} from 'react-scroll-section';

import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import { Container, Separator } from "../components/styledComponents"



const useStyles = makeStyles(theme => ({
  top: {
    [theme.breakpoints.down(430)]: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  
  bottom: {
    flex: "1 0 60%",
    width: "100%",

    [theme.breakpoints.down(430)]: {
      flexDirection: "column-reverse",
      alignSelf: "center",
    },
  },

  bottomLeft: {
    flex: "1 0 45%",
    [theme.breakpoints.down(430)]: {
      alignItems: "flex-start",
    },
  },

  bottomRight: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down(430)]: {
      justifyContent: "flex-end",
    },
  },

  landingTitle: {
    color: "var(--color-primary-light)",


    [theme.breakpoints.down(960)]: {
      fontSize: "15rem",
    },
    [theme.breakpoints.down(770)]: {
      fontSize: "12rem",
    },

    [theme.breakpoints.down(600)]: {
      fontSize: "10rem",
    },
    [theme.breakpoints.down(430)]: {
       fontSize: "8rem",
       margin: "1rem"
    },
  },


  landingTitleTwo: {
    [theme.breakpoints.up(1280)]: {
      visibility: "hidden"
      },
  },

  landingTitleThree: {
    [theme.breakpoints.down(1280)]: {
      visibility: "hidden"
      },
  },

  links: {
    color: "var(--color-primary-light)",
    '&:hover': {
      cursor: 'pointer'
    }
  },

  linkContainer: {
    [theme.breakpoints.down(430)]: {
      justifyContent: "center",
    },
  },

  name: {
    position: 'relative',
    marginTop: '1rem',
    color: "var(--color-primary-light)",
    fontSize: '5rem',

    [theme.breakpoints.down(430)]: {
    left: '50%',
    fontSize: '3.5rem',
    },

  }
}))

const Landing = () => {
  const projectSection = useScrollSection('projects')
  const aboutSection = useScrollSection('about')
  const contactSection = useScrollSection('contact')


  const classes = useStyles()

  return (
    <Container flexDirection="column" bgColor="--color-primary-dark">
      <Grid container wrap="nowrap" className={classes.top}>
        <Grid container item>
          <Typography variant="h1" className={classes.landingTitle}>
            Code
          </Typography>
        </Grid>
        <Grid container item style={{ position: "relative" }}>
          <Separator
            position="absolute"
            bottom="0"
            right="0"
            height="2.5px"
            width="125%"
          ><Typography variant='h5' className={classes.name}>Alex Krippner</Typography></Separator>
          <Typography variant="h1" className={`${classes.landingTitle} ${classes.landingTitleTwo}`}>
            Enthusiast
          </Typography>
        </Grid>
      </Grid>
      <Grid container item wrap="nowrap" spacing={5} className={classes.bottom}>
        <Grid container item justify="center" className={classes.bottomLeft}>
          <Grid container item style={{ width: "65%" }} alignItems="center">
            <Grid container item className={classes.linkContainer}>
              <Typography variant="h5">
                <Link
                  onClick={projectSection.onClick}
                  selected={projectSection.selected}
                  className={classes.links}
                >
                  Projects
                </Link>
              </Typography>
            </Grid>
            <Grid
              container
              item
              justify="center"
              className={classes.linkContainer}
            >
              <Typography variant="h5">
                <Link
                onClick={aboutSection.onClick}
                className={classes.links}
                >
                  About
                </Link>
              </Typography>
            </Grid>
            <Grid
              container
              item
              justify="flex-end"
              className={classes.linkContainer}
            >
              <Typography variant="h5">
                <Link
                 onClick={contactSection.onClick}
                className={classes.links}
                >
                  Contact
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          style={{ position: "relative" }}
          className={classes.bottomRight}
        >
          <Typography variant="h1" className={`${classes.landingTitle} ${classes.landingTitleThree}`}>
          Enthusiast
          </Typography>
          <Separator
            position="absolute"
            left="0"
            bottom="-10%"
            height="75%"
            width="2.5px"
            myQueries={{
              mobileLarge: { visibility: "hidden" },
              tabletSmall: {bottom: '-5%'}
            }}
         />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Landing
