import React from "react"
import styled from 'styled-components'


import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles, withStyles } from "@material-ui/core/styles"

import {FaReact} from 'react-icons/fa'
import { SiRedux, SiNodeDotJs, SiJavascript, SiMongodb, SiApollographql, SiGraphql } from "react-icons/si";

import {   Separator } from "../components/styledComponents"
import ProjectCard from '../components/projectCard'
import monDemo from '../assets/mon-demo.mp4'
import fernDemo from '../assets/fern-demo.mp4'
import summarizeDemo from '../assets/summarize-demo.mp4'

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: ${props => props.flexDirection || "row"}; 
  background: var(${props => props.bgColor});
  overflow: ${props => props.overflow || ''};
`

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    color: "var(--color-primary-light)",
    transform: "rotate(-90deg)",
    height: "min-content",

    [theme.breakpoints.down("xs")]: {
      transform: "rotate(0deg)",
    },
  },

  titleContainer: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },

  allCardsContainer: {
    overflow: 'auto',
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },

  cardContainer: {
    width: "min-content",
  },

  cardTitle: {
    color: "var(--color-primary-dark)",
  },

  card: {
    height: "40rem",
    width: "40rem",
    background: "var(--color-white-medium)",
    zIndex: 9999,
  },

  techIcons: {
    marginRight: '1rem',
    fontSize:'4rem',
    color:'var(--color-primary-dark)' 
  }
}))

const CardContainer = withStyles({
  root: {
    "&.MuiGrid-root": {
      width: 'min-content',
      margin: '2rem'
    },
  },
})(Grid)

const links = {
  monGit: 'https://github.com/alex-krippner/JLPT-N2-StudyApp',
  monDemo: 'https://mon.alexkrippner.com',
  fernGit: 'https://github.com/alex-krippner/fern',
  fernDemo: 'https://fern.buzz/',
  summarizeGit: 'https://github.com/alex-krippner/summarize'
}

const fernBodyText = 'Restaurant website with a shop build with REST API'
const monBodyText= 'App to help you study Japanese'
const summarizeBodyText = 'Get a summary overview of a github user public profile and repositories'



const Projects = () => {
  const classes = useStyles()
  return (
    <Container bgColor="--color-primary-medium" style={{ flexWrap: "wrap", padding:"5rem" }} overflow='auto'>
      <Separator position="absolute" right="30%" height="35%" width="2.5px" myQueries={{tabletSmall: {visibility: 'hidden'}}} />
      <Grid
        container
        item
        justify='space-around'
        alignItems="center"
        sm={2}
        className={classes.titleContainer}
      >
        <Typography variant="h3" className={classes.sectionTitle}>
          projects
        </Typography>
      </Grid>
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        sm={10}
        className={classes.allCardsContainer}
      >
        <CardContainer
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
         
          <ProjectCard projectName='mon' src={monDemo} gitLink={links.monGit} demoLink={links.monDemo} techs={ [FaReact, SiRedux]} bodyText={monBodyText} />
        </CardContainer>
        <CardContainer
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <ProjectCard projectName='fern' src={fernDemo}  gitLink={links.fernGit} demoLink={null} techs={[SiJavascript, SiNodeDotJs, SiMongodb ]} bodyText={fernBodyText} />
        </CardContainer>
        <CardContainer
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <ProjectCard projectName='summarize' src={summarizeDemo}  gitLink={links.summarizeGit} demoLink={null} techs={[FaReact, SiApollographql, SiGraphql ]} bodyText={summarizeBodyText} />
      </CardContainer>
      </Grid>
    </Container>
  )
}

export default Projects


