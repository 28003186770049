import React from "react"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import { Container, Separator } from "../components/styledComponents"

const useStyles = makeStyles(theme => ({
  primaryDark: {
    color: "var(--color-primary-dark)",
  },

  primaryMedium: {
    color: "var(--color-primary-medium)",
  },



  center: {
    marginTop: '5rem',
    overflowY:'auto'
  },

  //   toolsTitle: {
  //     marginTop: "4rem",
  //   },

  toolListItem: {
    marginTop: "2rem",
  },
  
  aboutTitleContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    }
  },

  aboutTitle:{
    marginTop: '3rem',
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
      marginTop: 0,
},

    [theme.breakpoints.down('xs')]: {
      fontSize: '12rem',
      position: 'absolute',
      left: '-25%'
    },

    [theme.breakpoints.down(375)]: {
      fontSize: '10rem', 
    }

  },
}))

const About = () => {
  const classes = useStyles()

  return (
    <Container bgColor="--color-primary-light">
      <Grid container item justify="center" className={classes.left} xs={1}>
        <Separator
          height="75%"
          width="2.5px"
          color="--color-primary-dark"
          alignSelf="flex-start"
          myQueries={{
            mobileLarge: { display: 'none' },
          }}/>
      </Grid>
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        spacing={4}
        className={classes.center}
        xs={8}
      >
        
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          className={classes.toolsTitle}
          spacing={2}
         
        >
      <Grid item container>  <Typography variant="h6" className={classes.primaryMedium}>
      I am Alex. 
    </Typography></Grid>

          <Grid item container >
          
            <Typography variant="h6" className={classes.primaryMedium}>
            An enthusiastic programmer and aspiring fullstack developer. If I was
            not programming I would be baking bread or studying Japanese.</Typography>
          </Grid>
          <Grid item container >
            <Typography variant="h6" className={classes.primaryMedium} style={{overflow: 'auto'}}>
              I have deployed projects build with frontend technologies such as
              vanilla javaScript, react, html, and css. I have also worked with
              node.js, pug, and mongoDB. Always looking to learn more and
              excited about what the future holds.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems='center'
        className={classes.aboutTitleContainer}
        xs={4}
        sm={6}
      >
        <Typography variant="h2" className={`${classes.primaryDark} ${classes.aboutTitle}`}>
          about
        </Typography>

     
      </Grid>
    </Container>
  )
}

export default About

// <Grid container item>
// <Typography variant="h4" className={classes.primaryDark}>
//   my
// </Typography>
// <Typography variant="h4" className={classes.primaryMedium}>
//   Tools
// </Typography>
// </Grid>
// <Grid container item direction="column">
// <Grid container item>
//   <Typography variant="h5" className={classes.primaryDark}>
//     javaScript&nbsp;&#x0007C;
//   </Typography>
//   <Typography variant="h5" className={classes.primaryMedium}>
//     &nbsp;react node.js
//   </Typography>
// </Grid>
// <Typography
//   variant="h5"
//   className={`${classes.primaryDark} ${classes.toolListItem}`}
// >
//   html
// </Typography>
// <Typography
//   variant="h5"
//   className={`${classes.primaryDark} ${classes.toolListItem}`}
// >
//   css
// </Typography>
// <Typography
//   variant="h5"
//   className={`${classes.primaryDark} ${classes.toolListItem}`}
// >
//   pug
// </Typography>
// </Grid>
