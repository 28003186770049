import React from "react"
import styled from 'styled-components'

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from "@material-ui/core/styles"




const useStyles = makeStyles({
  
  
    cardTitle: {
      color: "var(--color-primary-dark)",
    },
  
    card: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: "40rem",
      width: "40rem",
      background: "var(--color-white-medium)",
      zIndex: 99,
    },

    cardContent: {
        flex: '1 0 20%'
    },

    linksContainer: {
        flex: '1 0 10%'
    },
  
    techIcons: {
      marginRight: '1rem',
      fontSize:'4rem',
      color:'var(--color-primary-medium)' 
    }
  })
  

const ButtonLink = withStyles({

    root: {
      '&.MuiButtonBase-root':{
        color: 'var(--color-primary-dark)',
        fontSize: '1.5rem',
      },
    },
  })(Button)
  
  const ProjectVideo = styled.video`
  width: 100%;
  object-fit: contain; 
  `
  
  const ProjectCard = (props) => {
    const {projectName, src, gitLink, demoLink, techs, bodyText} = props;
  
    const classes = useStyles()
  
  
    return (
  
      <Card className={classes.card}>
        <ProjectVideo autoPlay muted loop>
        <source src={src} type="video/mp4"/>
        </ProjectVideo>
        <CardContent className={classes.cardContent}>
          <Grid container  justify='space-between'>        
            <Typography variant="h5" className={classes.cardTitle} >
              {projectName}         
            </Typography>
            <Grid  container item xs={4}  wrap='nowrap'>
            {techs.map((tech) => React.createElement(tech, {className: classes.techIcons, key: tech}))}
            
            </Grid>         
          </Grid>
          <Typography variant="body1" color="textSecondary" component="p">
    {bodyText}
          </Typography>
        </CardContent>
      
        <CardActions className={classes.linksContainer}>
          <ButtonLink size="medium" href={gitLink}>
            GitHub
          </ButtonLink>
          {demoLink ? <ButtonLink size="medium" href={demoLink} >
          Demo
        </ButtonLink> : '' }          
        </CardActions> 
      </Card>
  
    )
  }

  export default ProjectCard