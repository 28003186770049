import React from "react"
import {
  ScrollingProvider, 
  Section
} from 'react-scroll-section';

import About from "../sections/about"
import Contact from "../sections/contact"
import Landing from "../sections/landing"
import Projects from "../sections/projects"
import Footer from "../sections/footer"

const IndexPage = () => (
  //create three sections
  <ScrollingProvider>
    <Landing />
    <Section id='projects'>
      <Projects />
    </Section>
    <Section id='about'>
      <About />
    </Section>
    <Section id='contact'>
      <Contact />
    </Section>  
    <Footer/>
  </ScrollingProvider>
)

export default IndexPage
